<template>
  <v-container fluid fill-height pa-0 :class="{'blur-content': isModal}">
     <div class="lang-selector-wrapper">
      <v-col cols="2">
        <v-select
          class="notranslate"
          v-model="currentLanguage"
          :items="$hyper.languages"
          item-text="name"
          item-value="code"
          label="Language"
          return-object
          solo
          @select="changeLanguage"
          flat
          style="min-width:150px; height:0px;"
          ref="languageSelector"
        >
           <template v-slot:item="{ item }">
              <v-list-item class="notranslate" @click="changeLanguage(item)">
                {{ item.name }}
              </v-list-item>
            </template>
          </v-select>
        </v-col>
      </div>
    <v-row no-gutters class="login-page w-100">
      <v-col cols="2" class="alignment">
        <img src="@/assets/svg/producer.svg" class="producer-logo pr-2" />
      </v-col>
      <v-col cols="2" class="alignment">
        <img src="@/assets/svg/businessOwner.svg" class="business-logo" />
      </v-col>
       <v-col cols="12" md="4" class="center-alignment login-form-div">
         <v-card class="login-form-wrapper text-center" elevation="0">
          <img src="@/assets/svg/logo-colored.svg" class="logo" />
          <p class="login-text">LOGIN</p>
          <v-form class="login-form" @submit="submitForm">
            <div class="login-options">
              <div class="login-option-wrapper">
                <v-btn
                  class="login-option"
                  style="width:100%" elevation="0"
                  :href="googleAuthLink"
                >
                  <img src="@/assets/svg/google.svg" class="login-option-icon"/>
                  Continue with Google
                </v-btn>
              </div>
            </div>
            <v-row align="center" class="px-2">
              <v-divider></v-divider>
              <div class="mx-2">or</div>
              <v-divider></v-divider>
            </v-row>
            <BaseInput
              solo
              flat
              placeholder="Email Address"
              name="email"
              class="pt-2"
              v-model.trim="email"
              :status="errors('email').length ? 'error': 'normal'"
              :error-messages="errors('email')"
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
            />
            <BaseInput
              solo
              flat
              placeholder="Password"
              name="password"
              class="pt-3"
              v-model.trim="password"
              :type="passwordFieldType"
              :status="errors('password').length ? 'error': 'normal'"
              :error-messages="errors('password')"
              :appendIcon="(passwordFieldType === 'password') ? 'mdi-eye' : 'mdi-eye-off'"
              :appendIconCallback="togglePasswordDisplay"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
            />
            <!-- <v-checkbox
              label="Keep me signed in"
              class='m-0 pa-0 remember-check-box'
              off-icon="mdi-checkbox"
              on-icon= 'mdi-check'
              color="info"
              v-model="rememberMe"
            /> -->
            <div class="btn-container">
              <div class="btn-div">
                <v-btn
                class="login-button btn-purple"
                type="submit"
                block
                :disabled="$v.$dirty && $v.$invalid">
                  Login with Email
                </v-btn>
              </div>
            </div>
            <div class="margin-top">
              <a
              @click="setModal"
              class="forgot-password-text">Forgot Password?</a>
            </div>
            <div class="need-margin-top">
              <p class="need-act">
                Need an account?
                <router-link :to="{ name: 'CustomerType'}" class="forgot-password">
                  Sign Up
                </router-link>
              </p>
            </div>
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="1" class="alignment">
        <img src="@/assets/svg/creative.svg" class="creative-logo" />
      </v-col>
      <v-col cols="3" class="alignment">
        <img src="@/assets/svg/photoshootEquipment.svg" class="photo-logo" />
      </v-col>
    </v-row>
    <v-row class="horizontal-line">
      <v-divider class="divider"/>
    </v-row>
    <RecoverPassword v-if="isModal" :modal="isModal" @closeModal="isModal=false"/>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import { getNextOnboardingStepInfo } from '@/helpers/onboarding';
import BaseInput from '@/components/common/BaseInput';
import RecoverPassword from '@/components/onBoard/RecoverPassword';
import { identifyUserForLogRocket } from '@/helpers'

const namespace = 'user';
export default {
  name: 'Login',
  components: { BaseInput, RecoverPassword },
  data() {
    return {
      isModal: false,
      passwordFieldType: 'password',
      email: '',
      password: '',
      rememberMe: false,
      currentLanguage: {
        code: 'en',
        name: 'English',
      },
    };
  },
  computed: {
    googleAuthLink() {
      const baseUrl = process.env.VUE_APP_BASE_API_URL || 'http://localhost:3000';
      return `${baseUrl}/auth/google`;
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v[field].$dirty) return errors;
        switch (field) {
          case 'email':
            if (!this.$v.email.email) { errors.push('Please provide a valid email address'); }
            if (!this.$v.email.required) { errors.push('Please provide your email address'); }
            break;
          case 'password':
            if (!this.$v.password.required) { errors.push('Please provide your password'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  mounted() {
    localStorage.setItem('loginType', 'login');
    const cookieLanguage = this.$hyper.getLanguageInfoFromCookie();
    if (!cookieLanguage) {
      localStorage.removeItem('app_language');
    }
    const languageCode = localStorage.getItem('app_language') || 'en';
    const language = this.$hyper.getLanguageInfoFromCode(languageCode);
    this.currentLanguage = language;
    this.changeLanguage(languageCode);
  },
  methods: {
    ...mapActions(namespace, ['loginUser']),
    changeLanguage(language) {
      this.currentLanguage = language;
      this.$hyper.translate(language.code, () => {
        localStorage.setItem('app_language', language.code);
      });
      this.$refs.languageSelector.blur();
    },
    togglePasswordDisplay() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    setModal() {
      this.isModal = true;
    },
    async submitForm(e) {
      e.preventDefault();
      console.info('Attempting login.')
      try {
        identifyUserForLogRocket(this.email)
      } catch (err) {
        console.info('Error identifying user at login.')
      }
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const { password, rememberMe } = this;
        const response = await this.loginUser({
          user: {
            email: this.email,
            password,
          },
          rememberMe,
        });
        if (response.success) {
          // check if onboarding flow completed or not
          const { user } = response;
          // const userBusiness = _.get(user, 'userBusinesses[0]', {});
          this.$hyper.translate(user.preferredLanguage || 'en', () => {
            localStorage.setItem('app_language', user.preferredLanguage || 'en');
          });
          const userRole = user.role.name;
          const maxState = userRole === 'creative' ? 10 : 8;
          const payload = {
            email: this.email,
            password,
          };
          if (['client', 'creative'].includes(userRole) && user.onboardingState !== null) {
            if (Number(user.onboardingState) < maxState) {
              localStorage.setItem('BS_ONBOARDING_EMAIL', this.email);
              localStorage.setItem('BS_ONBOARDING_INFO', btoa(JSON.stringify(payload)));
              const nextStep = await getNextOnboardingStepInfo({
                onboardingState: Number(user.onboardingState) + 1,
                onboardingStepCompleted: user.onboardingStepCompleted,
                role: user.role.name,
              });
              if (nextStep.url) {
                this.$router.push({ path: nextStep.url });
              } else {
                this.$router.push({ path: '/' });
              }
            } else {
              this.$router.push({ path: '/dashboard' });
            }
          } else {
            this.$router.push({ path: '/dashboard' });
          }
        }
        console.error('Login error!', response)
      }
    },
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
};
</script>
<style lang="scss" scoped>
.lang-selector-wrapper{
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
  z-index: 99;
  top: 0.5rem;
  right: 0.2rem;
  ::v-deep .v-input .v-input__control .v-input__slot {
    border: 1px solid $neutral5;
  }
}
.horizontal-line {
  margin-top: -65px;
  margin-bottom: 50px;
}
.blur-content {
  filter: blur(5px)
}
.login-options{
  padding: 1rem 0rem;
  .login-option{
    display: flex;
    border-radius: 40px;
    border: 1px solid #AEB8BF;
    background: white !important;
    ::v-deep .v-btn__content {
      font-family: $fontFamily1;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #686868;
    }
  }
  .login-option-icon{
    height: 1.125rem;
    margin-right: 1rem;
  }
}
.login-page {
  height: 100%;
  padding-right: 60px;
  padding-left: 45px;
}
.center-alignment {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form-wrapper {
  z-index: 20;
  max-width: 440px;
  padding: 20px 0px;
  background: $neutral6;
  border: 1px solid $neutral5;
}
.logo {
  width: 75%;
}
.producer-logo {
  margin-bottom: 40px;
  width: 14%;
  position: absolute;
  z-index: 2;
}
.business-logo {
  margin-bottom: 58px;
  position: absolute;
  width: 10.5%;
  z-index: 2;
}
.creative-logo {
  margin-bottom: 28px;
  width: 22%;
  z-index: 2;
  position: absolute;
}
.photo-logo {
  width: 18%;
  margin-bottom: 10px;
  padding-left: 60px;
  z-index: 2;
  position: absolute;
}
.alignment {
  display: flex;
  align-items: flex-end;
  justify-content: center
}
.margin-top {
  margin-top: 30px;
}
.need-margin-top {
  margin-top: 8px;
}
.login-text {
  margin-top: 20px;
  margin-bottom: 10px;
  color: $neutral11;
  font-family: $fontFamily1;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}
.login-form {
  width: 85%;
  margin: auto;
  background: $neutral6;
  ::v-deep .v-icon.v-icon.v-icon--link {
    color: rgba(45, 45, 45, 0.2);
  }
  input:-internal-autofill-selected {
    background-color: red;
  }
  ::v-deep .v-input input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px #ffff inset !important;
    background-color: #fff;
  }
    ::v-deep .disabledState.v-text-field--solo .v-input__control > .v-input__slot {
    border: solid 1px #D6D6D6 !important;
  }
  .errorState.v-text-field--solo .v-input__control > .v-input__slot {
    background-color: #fff !important;
  }
}
::v-deep .theme--light.v-label {
  color: $neutral11;
  font-size: 15px;
  font-weight: 600;
}
.btn-container {
  display: flex;
  justify-content: center;
  .btn-div {
    // width: 200px;
    .login-button {
      border-radius: 40px;
      box-shadow: none;
      margin-top: 20px;
      color: $neutral6;
      ::v-deep .v-btn__content {
        font-family: $fontFamily1;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}
::v-deep .v-card:not(.v-card--flat):not(.v-picker--time):not(.v-picker--date) {
  border-radius: 5px !important;
}
.v-btn.v-size--default {
  height: 40px !important;
}
.forgot-password {
  font-family: $fontFamily1;
  font-size: 15px ;
  font-weight: 600;
}
.forgot-password-text {
  font-family: $fontFamily1;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
}
.need-act{
  font-family: $fontFamily1;
  font-size: 15px;
  color: $neutral11;
  font-style: normal;
  font-weight: bold;
  line-height: 18px;
}
::v-deep .v-input {
 input:-webkit-autofill {
   border-radius: 5px;
  -webkit-box-shadow:0 0 0 50px $neutral3 inset;
 }
}
@media (max-width: 1330px) {
  .divider {
    display: none;
  }
}
@media (min-width: 1367px) {
  .divider{
    max-width: 100%;
    margin: auto;
    margin-bottom: 0px;
    border: solid 2px rgba(223, 223, 223, 0.15);
    background: rgba(45, 45, 45, 0.15);
  }
}
@media (min-width: 600px) and (max-width: 960px) {
  .blur-content{
    width:428px;
  }
  .login-form {
    width: 50% !important;
    margin: auto;
  }
  .login-page {
    padding-left: 10px !important;
    padding-right: 10px !important;
    background: $neutral6;
  }
  .login-text {
    margin-top: 15px;
  }
  .login-button {
    margin-top: 20px;
  }
  .margin-top {
    margin-top: 20px;
  }
  .need-margin-top {
    margin-top: 5px;
  }
  .logo {
    margin-top: 120px !important;
    width: 40%;
  }
  .remember-check-box {
    padding-top: 12px !important;
  }
  .alignment {
    display: none;
  }
  .login-form-wrapper {
    height: 100%;
    padding: 0px;
  }
}
@media only screen and (min-device-width: 961px) and (max-device-width: 1340px) {
  .alignment {
    display: flex;
    align-items: center;
  }
  .login-form {
    width: 100%;
    margin-top: 10px;
  }
  .login-page {
    padding-left: 45px;
    padding-right: 65px;
  }
  .login-form-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
  }
  .logo {
    margin-top: auto;
    margin-bottom: 0px;
    width: 90%;
  }
  .producer-logo {
    width: 18%;
  }
  .business-logo {
    width: 13%;
  }
  .creative-logo {
    width: 28%
  }
  .photo-logo {
    padding-left: 120px !important;
    width: 29%;
  }
}
@media (max-width: 600px) {
  .logo {
    margin-top: 47px;
  }
  .lang-selector-wrapper {
    padding-right: 9rem !important;
  }
  .login-page {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .login-form {
    width: 90%;
  }
  .alignment {
    display: none;
  }
  .login-form-wrapper {
    height: 100%;
    padding: 0px;
  }
}
.remember-check-box {
  padding-top: 16px !important;
    ::v-deep {
      .v-icon{
        background-color: white !important;
        border-radius: 6px;
        border: 2px solid #cfd5dd !important;
        &.mdi-check{
          border: 2px solid $primary1 !important;
          font-size: 16px;
        }
      }
    }
  }
  ::v-deep .v-messages {
    font-size: 14px !important;
  }
  ::v-deep .v-input input {
    font-family: $fontFamily1;
    font-size: 15px !important;
    line-height: 18px;
  }
  ::v-deep .v-input input::placeholder {
    letter-spacing: -0.12px;
    font-family: $fontFamily1;
    font-weight: bold;
    font-size: 15px !important;
    line-height: 18px;
    color: #2D2D2D;
    opacity: 0.4;
  }
</style>
