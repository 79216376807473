<template>
    <v-container
      fluid
      v-if="modal">
      <Modal
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :modal="modal"
        width="430"
        :overlay-opacity="0.6"
        persistent>
          <v-card tile flat outlined class="pa-3">
            <v-card-title class='pt-2 pb-0 pl-2 pr-0 modal-header-title'>
                Recover Password<v-spacer></v-spacer>
                <v-icon class="pr-1 cursor-pointer" @click="closeModal">mdi-close</v-icon>
            </v-card-title>
            <v-form @submit="submitForm">
            <v-card-text class="pa-2 font-weight-medium black--text">
                <v-card-subtitle>
                    Enter the email address associated with your account.
                </v-card-subtitle>
                <BaseInput
                    background-color="#ffff"
                    solo
                    dense
                    flat
                    name="email"
                    class="contact-input"
                    v-model.trim="email"
                    :status="errors('email').length ? 'error' : 'normal'"
                    :error-messages="errors('email')"
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                />
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
                <v-btn text
                    class="py-0"
                    color="primaryGray1"
                    @click="closeModal"
                >
                    Cancel
                </v-btn>
                <v-btn color="info"
                    class="py-0 px-10 btn-purple"
                    type="submit"
                    :disabled="$v.$dirty && $v.$invalid"
                >
                    Send Reset Link
                </v-btn>
            </v-card-actions>
            </v-form>
          </v-card>
      </Modal>
    </v-container>
</template>
<script>
import {
  required, email,
} from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import BaseInput from '@/components/common/BaseInput';
import Modal from '@/components/common/Modal';

const namespace = 'user';
export default {
  name: 'RecoverPassword',
  components: {
    Modal,
    BaseInput,
  },
  data() {
    return {
      email: '',
    };
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(namespace, ['recoverUser']),
    closeModal() {
      this.$emit('closeModal', false);
    },
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const response = await this.recoverUser({
          email: this.email,
        });
        if (response.success) {
          this.$emit('closeModal', false);
        }
      }
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  computed: {
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v[field].$dirty) return errors;
        switch (field) {
          case 'email':
            if (!this.$v.email.email) {
              errors.push('Please provide a valid email address.');
            }
            if (!this.$v.email.required) {
              errors.push('Please provide your email address.');
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
 .v-card__subtitle {
    font-weight: normal;
    font-size: 15px !important;
    line-height: 18px;
    padding-left: 0px;
    padding-right: 0px;
    font-family: $fontFamily1;
    color: $charcoalBlack !important;
    font-style: normal;
}
 .v-input__slot {
    border: 1px solid $silver !important;
  }
}
.v-card__title {
    font-family: $fontFamily1;
    font-weight: bold !important;
    text-transform: capitalize !important;
    color: $charcoalBlack;
    font-size: 1.0625rem !important;
    line-height: 20px;
}
.v-card__actions {
  flex-wrap: wrap;
  padding-bottom: 18px;
  .v-btn {
    border-radius: 1.25rem;
    padding: 10px 1.25rem;
    height: 40px;
    box-shadow: none;
    color: #FFFFFF;
    ::v-deep .v-btn__content {
      font-family: $fontFamily1;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      line-height: 19px;
    }
  }
}
</style>
